<template>
  <section class="terms-of-use-section">
    <div class="service-main">
      <h1>서비스 이용약관</h1>
      <h2>MAXWORK SMART FACTORY 서비스 이용약관</h2>
    </div>
    <div class="service-content">
      <p>
        MAXWORK SMART FACTORY 이용 약관
        <br />
        <br />
        MAXWORK SMART FACTORY 이용약관(이하 “본 약관”이라 합니다)은 주식회사 맥스트(이하 ‘회사’라 한다.)가 제공하는
        MAXWORK SMART FACTORY서비스의 이용과 관련하여 회원과 회사 사이에 필요한 사항을 규정하며 아래와 같은 내용을 담고
        있습니다.
        <br />
        제1장 총칙
        <br />
        <br />
        제1조(목적)
        <br />
        본 약관은 회사가 제공하는 MAXWORK SMART FACTORY서비스(이하 ‘서비스’라 합니다) 이용과 관련하여 회사와 회원 사이의
        권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
        <br />
        <br />
        제2조(용어의 정의)
        <br />
        ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        1. “MAXWORK SMART FACTORY서비스”라 함은, 통상적인 산업 및 업무 현장에서 PC 웹, 휴대폰, 태블릿 등 스마트기기,
        스마트글라스 등 각종 유무선 기기를 통하여 증강현실(이하 AR) 기술을 기반으로 한 체크시트, 점검이력 관리 등
        설비점검, 설비 매뉴얼 및 원격 협업 도구를 제공하고 이를 활용할 수 있도록 하는 서비스를 의미합니다.
        <br />
        2. “회원”이라 함은, MAXWORK SMART FACTORY 사이트의 이용 약관에 동의하고 회원으로 가입한 사람을 의미합니다.
        회원은 관리자로서 회사로부터 상품(Trial/Pro/Enterprise)을 구매하고, 별도의 계약을 체결한 사용자에게 email 정보를
        기반으로 MAXWORK SMART FACTORY서비스의 각 기능(MSF 어드민, MSF 앱)에 대한 사용 권한을 부여하고, 사용자를
        관리합니다.
        <br />
        3. “상품”이라 함은, MAXWORK SMART FACTORY의 서비스의 판매 단위를 의미하며 세부내용은 다음과 같습니다.
        <br />
        가. 종류(Type): Trial/Pro/Enterprise
        <br />
        나. 일련 번호(Identify Number): 각 상품에 부여되는 고유 번호
        <br />
        다. 이용 기간: 상품 구매일로부터 사용 권한이 만료되는 날짜까지의 기간
        <br />
        라. 만료일: 상품의 사용 기간이 종료되는 날짜(서비스 구매일 익일로부터 1달 후 날짜의 23:59를 기준으로 한다.)
        <br />
        3. “도구”라 함은, 다음 각 목의 내용을 의미합니다.
        <br />
        가. MSF 어드민(Web): 설비 인프라 및 담당자, 점검 히스토리를 통합 관리하고 체크시트와 AR 콘텐츠를 저작하며
        원격지원 기능을 활용하는 웹페이지
        <br />
        나. MSF 앱(App): 산업 현장에서 다수의 사용자가 설비 점검과 원격지원 기능을 활용하여 협업하는 도구
        <br />
        4. “권한”이라 함은, MAXWORK SMART FACTORY 상품을 구매한 사용자가 각 기능을 사용할 수 있도록 관리자가 부여하는
        허가를 의미합니다.
        <br />
        5. “Email ID(이메일 아이디)”라 함은, 회원이 기존에 사용 중인 Email 주소를 회원 식별과 서비스 이용을 위하여 ID로
        사용하는 것을 의미합니다.
        <br />
        6. “비밀번호(패스워드)”라 함은, 이메일 아이디에 대하여 회원이 통신상에서 자신의 비밀 보호를 위하여 선정한 문자와
        숫자의 조합을 의미합니다.
        <br />
        7. “이용계약”이라 함은, 서비스 이용과 관련하여 회사와 회원 간에 체결하는 계약을 의미합니다.
        <br />
        8. “약정”이라 함은, 유료 서비스 이용 시 회원과 회사간에 서비스 이용기간을 미리 정하는 것을 의미합니다.
        <br />
        9. “계약해지”라 함은, 회사 또는 신청자가 서비스 개통 후 서비스 이용계약을 해약하는 것을 의미합니다.
        <br />
        10. “계약해제”라 함은, 회사 또는 신청자가 서비스 개통 전 서비스 이용계약을 해약하는 것을 의미합니다.
        <br />
        11. “서비스 일시중지”라 함은, 회사가 정한 일정한 요건에 따라 일정기간 동안 서비스의 일시 중지하는 것을
        의미합니다.
        <br />
        12. “서비스 개통일”이라 함은, 회원의 서비스 이용신청 후, 회원이 해당 서비스를 이용할 수 있도록 회사가 필요한
        조치하여 완료한 일자를 의미합니다.
        <br />
        13. “변경일”이라 함은, 회원이 서비스 내용에 대한 변경을 요청한 후, 회사가 회원의 요청사항들을 조치하여 완료한
        일자를 의미합니다.
        <br />
        14. “결제대행 사업자”라 함은, 회원에게 다양한 결제수단을 선택할 수 있도록 하기 위하여 회사가 지정한 전자지불
        서비스를 대행하는 전문 업체를 의미합니다.
        <br />
        15. “이용요금”이라 함은, 사용자가 서비스를 이용하기 위해 회사에 지불하는 사용료를 의미합니다.
        <br />
        16. “정기결제”라 함은, 상품 페이지를 통해 월별 정기결제 서비스 이용 계약을 맺은 고객의 결제 방식을 의미하며,
        최초 결제일로부터 한 달 간격으로 서비스 요금이 결제되며 결제대행업체를 통하여 이루어집니다.
        <br />
        17. “선결제”라 함은, 회사와 직접 계약(최소 3개월 이상에 대한 서비스 이용)을 맺고 서비스를 사용하는 고객의 결제
        방식을 의미합니다.
        <br />
        <br />
        제3조 (약관의 효력 및 개정)
        <br />
        ① 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 회사의 웹사이트(MAXWORK SMART
        <br />
        FACTORY.maxst.com) 초기 화면에 게시합니다. 다만, 약관의 내용은 회원이 연결화면을 통하여 볼 수 있도록 할 수
        있습니다.
        <br />
        ② 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호
        등에 관한 법률 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
        <br />
        ③ 본 약관에 동의하고 회원가입을 한 회원은 약관에 동의한 시점부터 동의한 약관의 적용을 받고 약관의 변경이 있을
        경우에는 변경의 효력이 발생한 시점부터 변경된 약관의 적용을 받습니다.
        <br />
        ④ 회사가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 회사의 웹사이트 초기 화면에
        그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관 내용을 변경하는 경우에는
        최소한 30일 이상의 사전 유예기간을 두고 공지하며, 공지 외에 일정기간 회원의 이메일로 전송하는 방법 등의 전자적
        수단을 통해 통지합니다. 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽게 표시합니다.
        <br />
        ⑤ 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 적용 예정일까지 거부의 의사표시를 하지 않으면 동의한 것으로
        본다는 뜻을 명확하게 공지 또는 통지하였음에도, 회원이 적용 예정일까지 명시적으로 거부의사를 표명하지 아니한 경우
        회원이 개정약관에 동의한 것으로 봅니다.
        <br />
        ⑥ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 해당 회원에 대하여 개정약관의 내용을 적용할 수 없으며, 이
        경우 회원은 본 약관 제6조에 따라 이용계약을 해지할 수 있습니다. 다만, 회사가 개정약관 적용에 부동의 한 회원에게
        기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 해당 회원과의 이용계약을 해지할 수 있습니다.
        <br />
        <br />
        제4조 (약관 외 준칙)
        <br />
        ① 본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의
        규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에
        따릅니다.
        <br />
        ② 회사의 MAXWORK SMART FACTORY서비스는 해외에서도 자유롭게 이용할 수 있습니다. 이를 위해 회사는 영문 이용약관을
        제공할 예정입니다. 국문 이용약관과 영문 이용약관의 해석이 충돌하는 경우, 국문 이용약관의 효력이 우선합니다.
        <br />
        <br />
        <br />
        제2장 서비스의 이용
        <br />
        <br />
        제5조(회원가입 및 서비스 이용계약 체결)
        <br />
        ① 회사가 제공하는 서비스의 이용을 원하는 회원이 본 약관의 내용에 동의를 한 다음 회사가 제시하는 양식과 절차에
        따라 이용신청을 하고, 그 신청한 내용에 대하여 회사가 승낙함으로써 회사와 회원 간에 이용계약이 성립됩니다.
        <br />
        ② 이용신청은 서비스를 이용하고자 하는 자가 이용 신청 시 본 약관에 ‘동의합니다.’ 버튼을 클릭함으로써 동의의
        의사표시를 하고, 웹사이트의 회원 가입 화면(또는 서비스의 회원 등록 화면)에서 회원이 다음 각 호의 사항을 가입신청
        양식에 기입하는 방법에 의합니다.
        <br />
        1. 상호이름(회원이 속한 회사이름)
        <br />
        2. 회원이름
        <br />
        3. 비밀번호
        <br />
        4. 이메일(전자우편) 주소
        <br />
        5. 기타 회사가 필요하다고 인정하는 사항
        <br />
        ③ 회사가 제공하는 서비스를 이용하고자 하는 자가 미성년자 또는 피성년후견인인 경우에는 법정대리인 등 보호의무자의
        동의를 얻어 이용신청을 하여야 합니다. 본항에 따라 동의를 한 보호의무자는 해당 미성년자 또는 피성년후견인의
        서비스 이용에 대한 요금납입책임자가 됩니다.
        <br />
        ④ 회사는 회원으로 가입할 것을 신청한 가입 신청자 중 다음 각 호에 해당하는 사유가 있는 자에 대하여는 이용신청에
        대한 승낙을 거부할 수 있습니다.
        <br />
        1. 실명이 아니거나 타인의 명의를 이용한 경우
        <br />
        2. 기재 내용에 허위, 기재누락, 오기 등이 있는 경우
        <br />
        3. 회사로부터 이용정지 당한 회원이 그 이용 정지 기간 중에 이용계약을 임의 해지하고 재이용 신청을 하는 경우
        <br />
        4. 과거에 본 약관의 위반 등의 사유로 서비스 이용계약이 해지 당한 경력이 있는 경우
        <br />
        5. 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우
        <br />
        6. 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우
        <br />
        ⑤ 이용계약의 성립 시기는 회사가 회원의 이용신청을 승낙한 시점으로 합니다.
        <br />
        ⑥ 이용계약이 성립되면 회사는 회원에게 본 약관이 정하는 바에 따라 회원 아이디를 부여합니다. 회원 아이디는
        원칙적으로 변경이 불가능하며, 불가피한 사유로 인하여 변경하고자 하는 경우에는 해당 아이디를 해지하고 새로운
        아이디로 가입을 하여야 합니다.
        <br />
        <br />
        제6조(회원탈퇴 및 이용계약의 해제, 해지 등)
        <br />
        ① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 관련 법령 등이 정하는 바에 따라 이를 즉시 처리하여야
        합니다.
        <br />
        ② 회원이 탈퇴할 경우 회사는 관련 법령 및 개인정보처리방침에 따라 회원 정보를 보유하는 경우를 제외하고는 해지
        즉시 회원의 모든 데이터를 삭제합니다.
        <br />
        ③ 회원의 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 부담하여야 합니다.
        <br />
        ④ 회사는 회원에게 다음 각 호의 어느 하나에 해당하는 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다.
        <br />
        1. 회원이 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
        <br />
        2. 회원이 고의로 회사의 영업을 방해한 경우
        <br />
        3. 다른 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 선량한 풍속 기타 사회질서에
        위배되는 행위를 한 경우
        <br />
        4. 회원이 이 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우
        <br />
        5. 회원에게 제5조에서 정한 이용계약의 승낙거부사유가 있음이 확인된 경우
        <br />
        6. 기타 회사가 합리적인 판단에 기하여 서비스 제공을 거부할 필요가 있다고 인정할 경우
        <br />
        ⑤ 회사가 이용계약을 해지하는 경우 회사는 회원에게 문자메시지나 전자우편 등으로 해지 사유를 밝혀 해지의사를
        통지합니다. 이 경우 회사는 해지를 하기 전에 상당한 기간을 정하여 회원의 이의 신청의 기회를 부여합니다. 다만, 본
        약관에서 정하는 사유가 있을 경우에는 별도의 이의 신청 기간을 부여하지 않을 수 있습니다.
        <br />
        ⑥ 이용계약이 회사에 의해 해지되는 경우 회원의 재이용 신청에 대해 회사는 그 승낙을 거절할 수 있습니다.
        <br />
        <br />
        제7조 (회원에 대한 통지)
        <br />
        ① 회사는 회원이 회사에 제출한 이메일 주소 또는 연락처(전화번호, 휴대폰번호) 등을 이용해 회원에 대한 통지를 할 수
        있습니다.
        <br />
        ② 회사는 불특정다수 회원에 대한 통지의 경우 홈페이지 및 해당 서비스 게시판 등에 게시함으로써 개별 통지를 갈음할
        수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대해서는 개별통지를 합니다.
        <br />
        <br />
        제8조 (회원 정보의 제공 및 변경)
        <br />
        ① 회사는 회원의 서비스 이용계약 체결 이후 필요한 경우 회원의 동의 하에 회원의 정보를 추가로 수집할 수 있습니다.
        <br />
        ② 회원은 서비스 이용계약 체결 및 이용을 위해 제공한 개인정보를 회원 본인이 개인정보관리화면을 통하여 언제든지
        열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디 등은 수정이 불가능합니다.
        <br />
        ③ 회원은 회사에 제공한 회원정보에 변경이 있을 경우 온라인으로 수정하거나 이메일 등의 수단으로 회사에 변경사항을
        알려야 합니다. 본항의 변경사항을 회사에 알리지 않아 발생한 불이익은 회원이 부담합니다.
        <br />
        제9조(서비스의 제공)
        <br />
        ① 회사는 회원의 이용신청을 승낙하고, 회사와 회원 간에 별도로 협의한 경우를 제외하고는 회사에서 제시한 절차에
        따라 서비스 이용료를 선불로 납입한 때부터 서비스 개시를 원칙으로 합니다.
        <br />
        ② 회원은 본 약관이 정하는 바에 따라 회사가 제공하는 MAXWORK SMART FACTORY 서비스를 이용할 수 있으며 MAXWORK
        SMART FACTORY 서비스는 다음 각 호와 같이 이루어져 있습니다.
        <br />
        1. MSF 어드민: 설비 인프라 및 담당자, 점검 히스토리를 통합 관리하고 체크시트와 AR 콘텐츠를 저작하며 원격지원
        기능을 활용하는 웹페이지
        <br />
        2. MSF 앱: 산업 현장에서 다수의 사용자가 설비 점검과 원격지원 기능을 활용하여 협업하는 도구
        <br />
        ③ 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중 무휴 1일 24시간을 원칙으로 합니다. 다만,
        시스템 점검이나 기타 회사가 필요한 경우, 서비스의 일부 또는 전부의 사용을 제한할 수 있습니다.
        <br />
        ④ 회사가 회원에게 제공하기로 한 MAXWORK SMART FACTORY 서비스의 내용을, 서비스에 영향을 주는 기술적 환경의 변화
        등의 사유에 의하여 변경하여야 하는 경우에는 그 사유를 회원에게 제7조의 방법으로 즉시 통지하고 서비스의 추가 또는
        변경된 사항을 서비스 제공 사이트에 게시하거나 이메일 등으로 별도 공지할 수 있습니다.
        <br />
        ⑤ 전항의 경우 회사는 이로 인하여 회원이 입은 손해를 배상합니다. 다만, 회사가 고의 또는 과실이 없음을 입증하는
        경우에는 그러하지 아니합니다.
        <br />
        ⑥ MAXWORK SMART FACTORY 서비스의 구체적인 종류 및 요금제, 이용요금은 서비스 홈페이지(MAXWORK SMART
        FACTORY.maxst.com)에 공시하는 바에 따릅니다.
        <br />
        <br />
        제10조(서비스 요금의 계산)
        <br />
        ① 이용요금의 과금은 회원이 선택한 요금 납부 결제 방법에 근거한 서비스 개통일로부터 이루어지며, 회원의 실제
        서비스 이용 여부와 상관없이 서비스 기간에 따라 계산됩니다.
        <br />
        ② 일할 이용요금의 계산은 00:00부터 23:59까지를 1일로 하며, 서비스 제공의 개시 시간 또는 종료시간이 1일의 중도인
        경우에는 이를 1일로 간주합니다.
        <br />
        ③ 월 이용요금의 계산은 서비스 개통일로부터 익월 개통일 1일 이전까지를 1개월의 이용요금으로 하며, 연 이용료의
        계산은 서비스 개통일로부터 익년 개통일 1일 이전까지를 1년의 이용요금으로 합니다.
        <br />
        ④ 연 이용료의 계산은 서비스 개통일로부터 익년 개통일 1일 이전까지를 1년의 이용요금으로 하며, 기타 조건은 월
        이용요금의 계산과 동일합니다.
        <br />
        <br />
        제11조(서비스 요금 납부(결제) 방법
        <br />
        ① MAXWORK SMART FACTORY 서비스의 요금제는 다음 각 호와 같습니다.
        <br />
        1. 정기결제: 상품 페이지를 통해 월별 정기결제 서비스 이용계약을 맺은 고객의 결제 방식으로, 최초 결제일로부터
        한달 간격으로 서비스 요금이 결제되며 결제대행업체를 통하여 이루어집니다.
        <br />
        2. 선결제: 회사와 직접 계약(최소 3개월 이상 기간에 대한 서비스 이용)을 맺고 서비스를 사용하는 고객의 결제방식
        <br />
        ② 요금 납부(결제)는 계좌이체, 신용카드결제 등 회사에서 제공하는 결제방식을 통해서 가능합니다. 단, 회사의 사정상
        서비스별로 결제수단이 일부 제한될 수 있습니다.
        <br />
        ③ 상기 각 결제수단을 운영하는 결제대행 사업자가 별도로 있는 경우, 고객은 해당 결제수단을 이용하기 전에 해당
        결제대행 사업자가 제시하는 절차를 이행하여야 합니다. 회사의 서비스 이용요금을 결제할 때, 해당 결제대행 사업자가
        제시하는 절차 이행 및 이용약관에 동의한 것으로 간주합니다.
        <br />
        ④ 무통장 입금일 경우에는 입금인과 신청서 상의 입금예정자가 다를 경우 신청자 또는 입금인은 회사에 전화나 이메일,
        팩스, 우편 등으로 그 내용을 통지해야 합니다. 이의 누락으로 인한 모든 책임은 신청자에게 있습니다.
        <br />
        ⑤ 입금 확인은 회사의 영업 시간(평일 근무일, 월∼금, 오전9시∼오후6시)내에 이루어지며, 영업종료후의 입금은 익일
        영업 시간 내 처리 완료됩니다.
        <br />
        <br />
        제12조(정기결제 계약 및 선결제 계약의 해제 및 해지)
        <br />
        ① 정기결제 계약을 해지하고자 하는 경우에는, MAXWORK SMART FACTORY 사이트에서 로그인 후 정기결제 해지 버튼을 통해
        직접 신청해야 하며 다가오는 다음 결제일부터 정기결제가 이루어지지 않고 그 달의 서비스 이용 기간은 유지됩니다.
        정기 결제일에 결제가 정상적으로 이루어지지 않은 경우는 아래와 같이 처리합니다.
        <br />
        1. 결제가 실패된 날로부터 한 달 간의 유예 기간을 부여함
        <br />
        2. 유예 기간 동안 결제대행업체를 통해 주기적으로 재결제를 시도함
        <br />
        3. 유예 기간 동안 정상적인 결제가 이루어질 경우는 즉시 정상적인 서비스 이용이 가능함. 다만 결제일은 최초의
        정기결제일이 그대로 유지됨
        <br />
        4. 유예 기간 동안 정상적인 결제가 이루어지지 않을 경우에는 유예 기간 종료 이후 서비스 사용이 즉시 중단됨. 이
        경우 서비스 사용에 대한 컨텐츠(통화 기록, 프로젝트, 태스크 등)은 1년간 보존되며 휴면 회원으로 처리됨. 휴면 기간
        1년 내 서비스 재이용을 원할 경우는 결제 실패된 금액에 대한 정산 후 정상 이용이 가능 ② 선결제 계약을 이용기간
        중에 해지하고자 하는 경우에는, 관리자인 회원이 직접 회사에게 유선, 메일, 우편, 팩스 등의 방법으로 해지희망일
        7일전까지 회사에 해지 의사를 명확히 전달해야 하며 해지 후 3일 이내에 다음 각 호에서 정하는 바에 따라 남은
        사용기간에 해당하는 서비스 이용요금을 환불합니다. 그러나 선결제 이용 계약기간의 잔여기간이 3개월 이하에 해당하는
        경우에는 잔여 기간에 대한 환불이 불가합니다.
        <br />
        1. 해지 요청일 익일 기준으로 일할 계산하여 남은 사용기간 동안의 요금을 환불함
        <br />
        2. 결제 시 적용된 할인 금액은 환불 시에는 적용되지 않음(정가에 준해 산출된 금액으로 환불함)
        <br />
        <br />
        제13조(서비스의 중단)
        <br />
        ① 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
        <br />
        1. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하였을 경우
        <br />
        2. 서비스용 설비의 보수, 정기점검 등 공사로 인한 부득이한 경우
        <br />
        3. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
        <br />
        4. 천재지변, 국가비상사태 등 불가항력적인 사유가 있는 경우
        <br />
        5. 기타 회사의 업무상이나 기술상의 이유로 서비스 중지가 필요하다고 판단되는 경우
        <br />
        ② 서비스 중단이 있는 경우에는 회사가 제7조(회원에 대한 통지)에 정한 방법으로 회원에게 사전 통지합니다. 단,
        회사가 통제할 수 없는 사유로 인한 서비스 중단으로 인한 사전 통지가 불가능한 경우에는 사후에 이를 홈페이지 또는
        개별 서비스 관련 홈페이지에 공지할 수 있습니다.
        <br />
        ③ 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 고객의 손해에 대하여 책임지지 않습니다.
        <br />
        ④ 회사는 제1항의 사유가 발생한 경우 최대한 빠른 시간 내에 서비스를 재개하도록 최선의 노력을 다합니다.
        <br />
        <br />
        제14조(양도 금지)
        <br />
        ① 회원은 회사에서 제공받은 서비스를 제3자에게 재판매 할 수 없습니다. 다만, 재판매 목적으로 별도 계약이 체결된
        경우는 제외합니다.
        <br />
        ② 회원은 서비스의 이용권한, 기타 이용 계약상의 지위를 타인에게 양도, 증여할 수 없습니다.
        <br />
        <br />
        제15조(저작권의 귀속 및 이용제한)
        <br />
        ① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
        <br />
        ② 회원은 회사의 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이
        복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
        <br />
        ③ 회사는 약정에 따라 회원에게 귀속된 저작권을 사용하는 경우 당해 회원에게 통보하여야 합니다.
        <br />
        <br />
        <br />
        제3장 회사 및 회원의 의무 등
        <br />
        <br />
        제16조(회사의 의무)
        <br />
        ① 회사는 특별한 사정이 없는 한 고객이 신청한 서비스 개통희망일에 서비스를 이용할 수 있도록 합니다. ② 회사는
        업무상, 기술상 지장이 없는 한 연중무휴(24시간/365일) 서비스를 제공합니다. 다만, 서비스 안정화를 위해 실시하는
        정기 또는 비 정기 점검시간은 제외합니다.
        <br />
        ③ 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 즉시 처리하여야 합니다. 다만, 즉시
        처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 이메일 또는 유선 등으로 통보하여야 합니다.
        <br />
        ④ 회사는 서비스 제공과 관련하여 취득한 회원의 정보를 본인의 사전 승낙 없이 타인에게 배포할 수 없습니다. 단,
        관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구받은 경우, 정보통신윤리위원회의 요청이 있는 경우 또는
        회사가 정한 기간 동안의 이용요금을 체납하여 이용고객에게 통보 후 신용정보사업자 또는 신용정보집중기관에 제공하는
        경우에는 예외적으로 사용할 수 있습니다. ⑤ 회사는 회원의 의무를 준수하지 않은 서비스 이용으로 인해 발생한 결과에
        대해 어떠한 법적 책임을 지지 않습니다.
        <br />
        <br />
        제17조(회원의 의무)
        <br />
        ① 회원은 회원가입 신청 시 모든 사항을 사실에 근거하여 기재하여야 하며, 허위 또는 타인의 정보를 등록할 경우
        일체의 권리를 주장할 수 없습니다.
        <br />
        ② 회원은 회사가 제공하는 서비스를 이용함에 있어 다음 각 호의 행위를 하여서는 안됩니다.
        <br />
        1. 회원가입 신청이나 회원정보 변경 시 허위사실을 기재하거나, 다른 회원의 아이디 및 비밀번호를 도용, 부정하게
        사용하는 행위
        <br />
        2. 회원의 아이디나 계정을 타인과 거래하는 행위
        <br />
        3. 회사의 지적재산권, 제3자의 지적재산권 및 저작권 등 기타 권리를 침해하는 행위
        <br />
        4. 리버스 엔지니어링이나 해킹 또는 프로그램의 버그 등을 악용하여 서비스를 이용하는 행위
        <br />
        5. 서비스 운영을 고의로 방해하는 행위
        <br />
        6. 회사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 이메일을 발송하는
        행위
        <br />
        7. 기타 불법적이거나 부당한 행위
        <br />
        ③ 회원은 관계법령, 본 약관의 규정, 이용안내 및 주의사항 등 회사가 통지하는 사항을 등을 준수하여야 하며, 기타
        회사의 업무에 방해되는 행위를 하여서는 안됩니다.
        <br />
        ④ 회원은 서비스 이용에 대한 대가로서 본 약관에서 정한 이용요금을 납부할 의무가 있으며 서비스 이용요금 미납으로
        인해 발생되는 모든 문제에 대한 책임은 회원에게 있습니다. 단, 회사의 과실이나 회사가 인정한 사유에 의한 경우에는
        그러하지 않습니다.
        <br />
        ⑤ 회원이 회사의 서비스 장애를 초래하게 하였을 경우에는 그 보충, 수선 또는 기타 공사에 소요되는 비용과 이로 인해
        발생되는 회사의 손해를 전액 부담하여야 합니다.
        <br />
        ⑥ 회원이 제공하는 모든 정보의 저작권과 관련하여 발생하는 문제는 회원의 책임입니다.
        <br />
        ⑦ 선불로 납부한 이용요금은 타인에게 양도 또는 재판매 할 수 없으며 회사는 회원에게 제공된 아이디와 패스워드를
        현금 또는 기타 유가증권으로 교환해줄 의무가 없습니다.
        <br />
        <br />
        제18조(회원의 아이디와 비밀번호 관리에 대한 의무와 책임)
        <br />
        ① 회원은 회원 아이디 및 비밀번호 관리를 철저히 하여야 합니다.
        <br />
        ② 회원 아이디와 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있으며,
        회사의 시스템 고장 등 회사의 책임이 있는 사유로 발생하는 문제에 대해서는 회사가 책임을 집니다.
        <br />
        ③ 회원은 본인이 아이디와 비밀번호를 제3자에게 이용하게 해서는 안되며, 회원 본인의 아이디와 비밀번호를
        도난당하거나 제3자가 사용하고 있음을 인지하는 경우에는 즉시 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야
        합니다.
        <br />
        ④ 회원은 서비스 이용권한 및 서비스 이용계약상의 지위는 제3자에게 양도하거나 대여할 수 없으며 또한 담보로 제공할
        수 없습니다. 회사는 회원이 이러한 행위를 하였을 경우 회원의 서비스 이용을 제한할 수 있으며, 회원의 상기 행위로
        야기된 결과에 대한 책임은 회원에게 있습니다.
        <br />
        ⑤ 회원의 아이디는 회사의 사전 동의 없이 변경할 수 없습니다.
        <br />
        <br />
        <br />
        제4장 개인정보보호 등
        <br />
        <br />
        제19조(개인정보의 보호)
        <br />
        ① 회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다.
        회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만 수집·이용됩니다. 회사는
        법령에 의하거나 회원이 별도로 동의하지 아니하는 한, 회원의 개인정보를 제3자에게 제공하지 아니합니다.
        <br />
        ② 회원의 개인정보보호에 관해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 단, 회사의 공식 사이트
        이외의 웹에서 링크된 사이트에서는 회사의 개인정보취급방침이 적용되지 않습니다. 또한 회사는 회원의 귀책사유로
        인해 노출된 정보에 대해서 일체의 책임을 지지 않습니다.
        <br />
        ③ 회사는 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 통계를 작성하여 이를 사용할 수 있고, 서비스를
        통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여
        경고하도록 사용하는 컴퓨터 브라우저의 설정을 변경할 수 있습니다. 다만 이렇게 수집된 개인정보는 전체적인
        통계자료로만 활용합니다.
        <br />
        <br />
        제20조(거래기록의 보존 등)
        <br />
        ① 회사는 정보통신망법 등 관계 법령에 의하여 거래기록을 최대 5년간 보존합니다. 개인정보의 보유기간에 관한 내용은
        회사의 개인정보처리방침에서 자세히 정합니다.
        <br />
        ② 회사 내의 데이터베이스에 보존된 자료와 청구기관 측의 데이터베이스에 보존된 자료의 기재내용이 상이하여 회사가
        회원에게 자료 기재 내용의 확인을 요구할 경우에는 회원은 이에 응해야 합니다.
        <br />
        <br />
        <br />
        제5장 손해배상 및 면책
        <br />
        <br />
        제21조(손해배상)
        <br />
        ① 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에
        발생하는 모든 손해를 배상하여야 합니다.
        <br />
        ② 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터
        손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받은 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야
        하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
        <br />
        ③ 회사는 무료로 서비스(테스트 목적 서비스 포함) 제공된 기간 중에 발생한 손해 또는 서비스 요금이 무료인 서비스
        이용과 관련하여 회원에게 발생한 손해에 대하여 어떠한 책임도 지지 않습니다.
        <br />
        ④ 회사의 귀책사유로 회원이 서비스를 이용하지 못하는 경우, 회사는 회원에 대하여 손해배상책임이 있습니다.
        <br />
        ⑤ 회사는 고객으로부터 서비스를 이용하지 못한 사실을 접수한 시간 (또는 회사가 인지한 시간)부터 서비스 재개를 위한
        가능한 조치를 취하며, 서비스를 다시 이용할 수 있게 된 경우 이 사실을 고객에게 통지합니다. 다만, 고객이 서비스
        이용불가 사실을 회사에 접수한 이후 4시간 이내 서비스가 정상화된 경우는 제외합니다
        <br />
        ⑥ 회원이 회사의 시스템 운영을 방해하는 등 고의 또는 과실로 법령, 본 약관, 공서양속에 위배하는 행위 등을 통하여
        회사에 손해를 발생시킨 경우 회원은 회사에 발생한 손해를 모두 배상해야 합니다.
        <br />
        <br />
        제22조(회사의 면책)
        <br />
        ① 회사는 국가의 비상사태, 천재지변 또는 이에 준하는 국가비상사태 등 불가항력으로 서비스를 제공할 수 없는 경우
        서비스 제공에 대한 책임을 지지 않습니다.
        <br />
        ② 회원의 고의 또는 과실 등 회원의 귀책사유로 인한 서비스의 이용장애에 대해 책임을 지지 않습니다.
        <br />
        ③ 전기통신서비스 특성상 불가피한 사유로 서비스 제공이 불가능하거나 서비스 제공 관련 시스템의 변경, 교체,
        업데이트 등을 위하여 일시적으로 서비스 제공이 중단되는 경우 책임을 지지 않습니다.
        <br />
        ④ 회원의 귀책사유로 서비스가 해지되어, 회원의 정보가 소실된 경우 책임을 지지 않습니다.
        <br />
        ⑤ 회원이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나, 서비스로부터 수반되는 잠재가치 및 서비스
        자료에 대한 취사선택 또는 이용으로 손해가 발생한 경우 책임을 지지 않습니다.
        <br />
        ⑥ 회사는 회원이 전송한 자료의 내용에 대해서는 책임이 면제됩니다.
        <br />
        ⑦ 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우 해당 거래로 인해
        발생하는 손실에 대해 책임을 지지 않습니다.
        <br />
        ⑧ 회사는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할
        책임도 없습니다.
        <br />
        <br />
        제23조(분쟁해결)
        <br />
        ① 회원과 회사는 서비스 이용과 관련해 발생한 문제를 원만하게 해결하기 위해 상호 성실히 협의함을 원칙으로 합니다.
        <br />
        ② 본 조 제1항의 협의에도 불구하고, 분쟁으로 인해 소송이 제기될 경우 회사의 소재지 법원을 관할법원으로 합니다.
        <br />
        ③ 회사와 고객 간에 제기된 소송에는 대한민국 법을 적용합니다.
        <br />
        <br />
        <br />
        부칙
        <br />
        본 약관은 2021년 9월 1일부터 시행합니다.
        <br />
        <br />
        <br />
        <br />
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TermsOfUse',
  props: [],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss">
@import 'TermsOfUse';
</style>
